import styled from "styled-components";
import { colors } from "../../../styles/variables";
import { TemplateAsComponent } from "../../TemplateAsComponent";
import { ExternalLink } from "../../links/ExternalLink";

export const StyledTerms = styled.div<{ withCheckboxes?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: ${colors.gray600};
  font-size: 13px;

  > div {
    ${({ withCheckboxes }) => withCheckboxes && `margin: 0;`}
  }
`;

type ThemeTerms = {
  [key: string]: {
    fontSize?: string[];
  };
};

export const StyledTerm = styled.div<{ location: string; index: number }>`
  ${({ theme, location, index }) => {
    // if the theme defines terms styles and terms in these locations
    if (theme.terms && ["login", "review", "signup"].includes(location)) {
      // set the font size for the current paragraph
      const termFontSize = (theme.terms as ThemeTerms)[location]?.fontSize;
      if (termFontSize && termFontSize[index]) {
        return `font-size: ${termFontSize[index]}`;
      }
    }
    // otherwise set the default
    return `font-size: 13px`;
  }}
`;

export const StyledBody = styled.div<{ withCheckboxes?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  ${({ withCheckboxes }) => withCheckboxes && `margin-left: 5px;`}
`;

export const StyledExternalLink = styled(ExternalLink)`
  color: ${({ theme }) => theme.color.primary};
`;

export const StyledTemplateAsComponentSmall = styled(TemplateAsComponent)`
  font-size: 11px;
`;
