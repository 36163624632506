interface ScrollOptions {
  ref?: React.RefObject<HTMLDivElement>;
  isDelayed?: boolean;
  top?: number;
}

export const scrollTo = (props?: ScrollOptions): void => {
  const { ref, isDelayed, top } = props || {};
  const scroll = () => {
    window.scrollTo({
      left: 0,
      top: ref?.current?.offsetTop || top || 0,
      behavior: "smooth",
    });
  };

  // setting timeout to allow Firefox / IE / Edge to be
  // ready to take scroll action (otherwise it sometimes
  // doesn't scroll as expected)
  setTimeout(scroll, isDelayed ? 500 : 200);
  return;
};
