import styled from "styled-components";
import { Device } from "@pepdirect/v3/styles/screenSizes";
import { ExternalLink } from "@pepdirect/v3/ui/ExternalLink";

export const StyledOuterWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const StyledInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 454px;
  padding: 24px;
  margin-top: 130px;

  @media ${Device.mobile} {
    margin-top: 50px;
  }
`;

export const StyledText = styled.div`
  font-size: 15px;
  line-height: 23px;
`;

export const StyledExternalLink = styled(ExternalLink)`
  margin: auto;
`;
